export function formatDate(date, separator, timeRequired) {
    if (separator == null)
        separator = "/";

    let d = Date.parse(date) != NaN ? date : null;

    if (d != null) {
        let dateObj = new Date(d);
        let dateFormatted = dateObj.getDate().toString().padStart(2, '0') + separator + (dateObj.getMonth() + 1).toString().padStart(2, '0') + separator + dateObj.getFullYear();

        if (timeRequired == true)
            dateFormatted += " " + dateObj.getHours().toString().padStart(2, '0') + ":" + dateObj.getMinutes().toString().padStart(2, '0');

        return dateFormatted;
    }
    else {
        return '';
    }
}

export function getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}